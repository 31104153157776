import React from "react";
import { SERVER_URL } from "../config/config.js";
import "./biltyReport.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReportTable from "./ReportTable";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import ReportExcel from "./ReportExcel.js";
import { useLocation, useHistory } from "react-router-dom";
import { useRef, useState, useEffect , useMemo} from "react";
let ex_data = [];

function BiltyProfitLoss({ sessionObject }) {
    const history = useHistory();
  const columns = React.useMemo(
    () => [
      {
        Header: "Trip No",
        accessor: "trip_no",
        width: "90px",
        minWidth: "90px",
        canFilter: true,
      },
      {
        Header: "Trip Date",
        accessor: "trip_date",
        width: "120px",
        minWidth: "120px",
        // Cell: ({ value }) => {
        //   value = value.split("T");
        //   return value[0];
        // },
        canFilter: true,
      },
      {
        Header: "Station From",
        accessor: "trip_from",
        width: "100px",
        minWidth: "100px",
        canFilter: true,
      },
      {
        Header: "Station To",
        accessor: "trip_to",
        width: "100px",
        minWidth: "100px",
        canFilter: true,
      },
      {
        Header: "Vehicle No",
        accessor: "vehicle_no",
        canFilter: true,
        width: "100px",
        minWidth: "100px",
      },
      {
        Header: "Packages",
        accessor: "pkgs",
        canFilter: true,
        width: "100px",
        minWidth: "100px",
      },
      {
        Header: "Weight",
        accessor: "wgt",
        canFilter: true,
        width: "100px",
        minWidth: "100px",
      },
      {
        Header: "No of LR",
        accessor: "total_lr",
        canFilter: true,
        width: "100px",
        minWidth: "100px",
      },
      {
        Header: "Lorry Hire",
        accessor: "lorry_hire",
        canFilter: true,
        width: "100px",
        minWidth: "100px",
      },
      {
        Header: "Total Amt",
        accessor: "total_amount",
        canFilter: true,
        width: "70px",
        minWidth: "50px",
      },
      {
        Header: "P&L",
        accessor: "p&l",
        canFilter: true,
        width: "100px",
        minWidth: "100px",
      },
      {
        Header: "P&L Percentage",
        accessor: "p/l_percentage",
        canFilter: true,
      },
    ],
    []
  );

  // We'll start our table without any data
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);
  const fetchIdRef = React.useRef(0);
  const fileInputRef = useRef(null);
  const download_ref = React.useRef(null);
  const sortIdRef = React.useRef(0);
  const [dateState, setDateState] = React.useState({
    date_from: new Date(),
    date_to: new Date(),
    in_stock: "1",
    pending_bill: "2",
    own_branch : "1",
  });

  const formatDateToString = (date, isStartOfDay = false) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const day = String(d.getDate()).padStart(2, "0");
  
    // Set time to 00:00:00 for the start of the day (if isStartOfDay is true)
    const hours = isStartOfDay ? "00" : String(d.getHours()).padStart(2, "0");
    const minutes = isStartOfDay ? "00" : String(d.getMinutes()).padStart(2, "0");
    const seconds = isStartOfDay ? "00" : String(d.getSeconds()).padStart(2, "0");
  
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  };

  const formatDateToDDMMYYYY = (date) => {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, "0");
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const year = String(d.getFullYear()); // Get full year
  
    return `${day}-${month}-${year}`;
  };
  
  
  
  const [finalInput, setFinalInput] = React.useState({});
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  let query = useQuery();

  useEffect(() => {
    const reportType = query.get("report_type");
    // myForm.setPageStateByField("voucher_type", voucher);
    setDateState({
      ...dateState,
      ["report_type"]: reportType,
    })
  }, []);

  const formatPLPercentage = (value) => {
    if (value != null) {
      return `${value.toFixed(2)}%`;
    }
    return '0.00%'; 
  };
  

  const fetchData = React.useCallback(
    async ({ pageSize, pageIndex, sortBy, customFilters, dateObject }) => {
      // This will get called when the table needs new data
      // You could fetch your data from literally anywhere,
      // even a server. But for this example, we'll just fake it.

      // Give this fetch an ID
      const fetchId = ++fetchIdRef.current;
      console.log("12323", sortBy, customFilters, fetchId);

      // Remove "companyId" from customFilters
      if (customFilters && customFilters.companyId) {
        delete customFilters.companyId;
      }

      for (let key in customFilters){
        console.log("Key", key)
        if (customFilters[key]== null){
          continue
        }
        if (customFilters[key].toLowerCase() == "none"){
          customFilters[key] = null
        }
      }
      // Set the loading state
      setLoading(true);

      if (fetchId === fetchIdRef.current) {
        console.log("Date state", dateObject);


        let newObject = {}
       
        console.log("NEWOBJECT ",newObject)
        newObject = {...customFilters}

        let inputData = {
          paginate: { number_of_rows: pageSize, page_number: pageIndex + 1 },
          sort_fields: sortBy,
          filter_fields: newObject,
          date_dict: {
            date_from: formatDateToString(dateObject.date_from, true), 
            date_to: formatDateToString(dateObject.date_to),
          },
        };

        setFinalInput(inputData);

        let url = SERVER_URL + "/report/get_p&l_bilty/";

        let response = await fetch(url , {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(inputData),
        });

        let resp = await response.json();
        console.log("Response :- ",resp)
        
        if (fetchId === fetchIdRef.current){
          if (resp["data"] && "total_rows" in resp) {
            const formattedData = resp["data"].map((item) => ({
              ...item,
              trip_date: formatDateToDDMMYYYY(item.trip_date), 
              "p/l_percentage": formatPLPercentage(item["p/l_percentage"]),
            }));
  
            setData(formattedData);
            setPageCount(Math.ceil(resp["total_rows"] / pageSize));
            console.log("Response Internal :- ", resp);
          }
          setLoading(false);
        }

      }
    },
    []
  );

  const fetchDataDebounced = useMemo(
    () => _.debounce(fetchData, 500),
    []
  );


  return (
    <div className="report-bilty">
      <div className="form-header">Bilty Profit & Loss Report</div>
      <div className="report-bilty-table-container">
        <div className="report-field-row ">
          <div>
            From Date:{" "}
            <DatePicker
              dateFormat="dd-MM-yyy"
              selected={dateState.date_from}
              onChange={(date) =>
                setDateState({
                  ...dateState,
                  ["date_from"]: date,
                })
              }
              
            />
          </div>
          <div>
            To Date:{" "}
            <DatePicker
              dateFormat="dd-MM-yyy"
              selected={dateState.date_to}
              onChange={(date) =>
                setDateState({
                  ...dateState,
                  ["date_to"]: date,
                })
              }
             
            />
          </div>

        </div>

        <ReportTable
          columns={columns}
          data={data}
          fetchData={fetchDataDebounced}
          dateObject={dateState}
          loading={loading}
          pageCount={pageCount}

        />

        <div className="form-footer">
    
          <button style={{ display: "none" }}>
            <ReactHTMLTableToExcel
              id="test-table-xls-button"
              className="download-table-xls-button"
              table="bilty_pl_report_excel"
              filename="tablexls"
              sheet="tablexls"
              buttonText="Download as XLS"
              ref={(a) => (download_ref.current = a)}
            />
          </button>
          <button
            className="download-table-xls-button"
            onClick={async () => {
              setLoading(true);
              let dataToSend = {
                date_dict: finalInput.date_dict,
                filter_fields: finalInput.filter_fields,
              };

              let url = SERVER_URL + "/report/get_p&l_bilty/";

              let resp = await fetch(url, {
                method: "POST",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(dataToSend),
              });
              let response = await resp.json();

              if (response["data"] && "total_rows" in response) {
                console.log(response["data"], "fsdfafsfds");
                
                ex_data = response["data"].map(item => ({
                  ...item,
                  "p/l_percentage": formatPLPercentage(item["p/l_percentage"]),
                  trip_date: formatDateToDDMMYYYY(item.trip_date),
                  total_amount: Number(item.total_amount).toFixed(2),
                  "p&l": Number(item["p&l"]).toFixed(2) 
                }));
              }
              setLoading(false);
              download_ref.current.handleDownload();
            }}
          >
            Download as XLS
          </button>
          {ReportExcel.excel_data_biltyPL(
            { columns },
            { ex_data },
            { fetchData },
            { dateState },
            { loading },
            { pageCount },
            { dateState }
          )}
        </div>
      </div>
    </div>
  );
}

export default BiltyProfitLoss;
