import React from "react";
import PodImageReport from "../PodImageReport";

export default function ReportPodStatement({ sessionObject }) {
  if (!sessionObject.sessionVariables["modules"].includes("pod-image-report")) {
    return;
  }
  return (
    <div className="page-bilty">
      <PodImageReport sessionObject={sessionObject} />
    </div>
  );
}
