import useSessionVariables from "../components/useSessionVariables.js";
export function applySchema(inputDataObject, schemaObject) {
  console.log({ inputDataObject });
  let outputObject = {};
  let newKey = "";
  for (var schemaKey in schemaObject) {
    /**
     * If schema key not present in object skip the same
     */
    if (!(schemaKey in inputDataObject)) {
      continue;
    }
    /**
     * Changing key of output object if needed
     */
    if (schemaObject[schemaKey] == "same") {
      newKey = schemaKey;
    } else {
      newKey = schemaObject[schemaKey];
    }
    /**
     * Converting value of output object to string
     */
    if (typeof inputDataObject[schemaKey] == "object") {
      if (inputDataObject[schemaKey] === null) {
        if (newKey == "input_date" || newKey == "bill_date") {
          outputObject[newKey] = new Date();
        } else {
          outputObject[newKey] = "";
        }
      } else {
        outputObject[newKey] = inputDataObject[schemaKey];
      }
    } else {
      outputObject[newKey] = inputDataObject[schemaKey];
    }
  }
  console.log({ outputObject });
  return outputObject;
}

export function checkAccess(module) {
  let sessionObject = useSessionVariables();

  if (sessionObject.sessionVariables["modules"].includes(module)) {
    return true;
  }
  return false;
}

export async function apiFetch(endpoint, options = {}, sessionObject) {
  try {
    const token = sessionObject.sessionVariables.access_token;
    const headers = {
      ...options.headers,
      Authorization: token ? `Bearer ${token}` : "",
    };

    const config = {
      ...options,
      headers,
    };

    const response = await fetch(endpoint, config);

    if (response.status === 401) {
      alert("Session expired. Please login again.");
      sessionObject.saveSessionVariableByField("access_token", "");
      window.location.href = "/";
      // toast.error("Session expired. Please login again.");
      localStorage.setItem("authError", "Session expired. Please login again.");
      return Promise.reject(new Error("Unauthorized"));
    }

    if (response.status === 423) {
      localStorage.setItem("errorPop", true);
      window.location.reload();
      sessionObject.saveSessionVariableByField("access_token", "");
      return Promise.reject(new Error("Change password"));
    }

    return await response;
  } catch (err) {
    console.error("Error in API request:", err);
    return Promise.reject(err);
  }
}
