import React, { useEffect, useState, useRef } from "react";
import { SERVER_URL } from "../config/config";
import { useHistory } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import './AddPhoto.css'

const AddPhoto = () => {
  const history = useHistory();
  const videoRef = useRef(null);
  const [photo, setPhoto] = useState(null);
  const [cameraActive, setCameraActive] = useState(true);
  const [captureDisabled, setCaptureDisabled] = useState(true);
  const [facingMode, setFacingMode] = useState("environment"); 
  const [myForm, setMyForm] = useState({ overlay: false });
  const [biltyId, setBiltyId] = useState(null);
  const [isBiltyNoEntered, setIsBiltyNoEntered] = useState(false);
  const [biltyIdFromUrl, setBiltyIdFromUrl] = useState(null);


  const isMobileDevice = () => {
    return /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
  };


  const startCamera = async () => {
    try {
      const supportedConstraints = navigator.mediaDevices.getSupportedConstraints();
      console.log("Supported constraints:", supportedConstraints);
  
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter(device => device.kind === "videoinput");
      const backCamera = videoDevices.find(device =>
        device.label.toLowerCase().includes("back")
      ) || videoDevices[0];
  
      const constraints = {
        video: {
          deviceId: backCamera ? { exact: backCamera.deviceId } : undefined,
          facingMode: facingMode,
          width: { ideal: 1920 }, 
          height: { ideal: 1080 },
          frameRate: { ideal: 30 },
        },
      };
  
      // Attempt to start with optimal constraints
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      console.log("Stream started with constraints:", constraints);
  
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.play();
      }
  
      setCaptureDisabled(false);
    } catch (error) {
      console.warn("High-quality camera settings failed. Retrying with dynamic fallback...", error);
  
      try {
        const fallbackConstraints = {
          video: true,
        };
  
        const fallbackStream = await navigator.mediaDevices.getUserMedia(fallbackConstraints);
  
        if (videoRef.current) {
          videoRef.current.srcObject = fallbackStream;
          videoRef.current.play();
        }
  
        setCaptureDisabled(false);
      } catch (fallbackError) {
        console.error("Failed to access camera:", fallbackError);
        alert("Unable to access the camera. Please check your permissions and try again.");
      }
    }
  };
  
  const stopCamera = async () => {
    if (videoRef.current) {
      const tracks = videoRef.current.srcObject?.getTracks();
      tracks?.forEach((track) => track.stop());
    }
    setCaptureDisabled(true);
  };

  const toggleCamera = async () => {
    try {
      stopCamera();
      setFacingMode((prevMode) => (prevMode === "environment" ? "user" : "environment"));
    } catch (error) {
      console.error("Error toggling camera:", error);
    }
  };
  
  useEffect(() => {
    if (cameraActive) {
      startCamera();
    } else {
      stopCamera();
    }
  }, [cameraActive, facingMode]); 

  useEffect(() => {
    return () => {
      if (videoRef.current) {
        stopCamera();
      }
    };
  }, []);

  const fetchBiltyData = async (biltyNo) => {
    const branchId = 1;
    const suffix = "AHM";
    const fyear = "24-25";
    const companyId = 1;
    // const fYear_fetch = JSON.parse(sessionStorage.getItem("financial_year_for_fetch")).financial_year_for_fetch;
    

    const apiCallUrl = `${SERVER_URL}/bilty/get_bilty_no_for_pod?bilty_no=${biltyNo}`;

    try {
      // const biltyResponse = await fetch(apiCallUrl);
      const biltyResponse = await fetch(apiCallUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        // body: JSON.stringify(requestBody),
      });
      const biltyData = await biltyResponse.json();
      console.log('ssss', biltyData)

      if (biltyResponse.ok) {
        setBiltyId(biltyData.bilty_id);
        setIsBiltyNoEntered(true); 
        return biltyData.bilty_id;
      } else {
        alert("Failed to fetch Bilty information. Please try again.");
      }
    } catch (error) {
      alert("An error occurred while fetching Bilty details.");
      console.error("Error fetching Bilty API:", error);
    }
  };

  const handleImageUpload = async () => {
    stopCamera();
    const file = await new Promise((resolve) => {
      const input = document.createElement("input");
      input.type = "file";
      input.accept = "image/*";
      input.onchange = (event) => resolve(event.target.files[0]);
      input.click();
    });
  
    // if () {
      setMyForm({ overlay: true });
  
      const apiUrl = `${SERVER_URL}/pod_image/check-qr?type=bilty_pod&client_name=rcc`;
      const formData = new FormData();
      formData.append("file", file);
  
      try {
        const response = await fetch(apiUrl, {
          method: "POST",
          body: formData,
        });
  
        const data = await response.json();
  
        if (!response.ok || data.status_code === 406) {
          const biltyNo = window.prompt(`Error: ${data.detail}\nPlease enter your Bilty No:`);
          if (biltyNo) {
            const fetchedBiltyId = await fetchBiltyData(biltyNo); 

            if (fetchedBiltyId) {
              await sendFileToPodAPI(fetchedBiltyId, file); 
            }
          } else {
            alert("Bilty No is required. Please try again.");
          }
        } else {
          alert("Image uploaded successfully");
          window.location.reload();
        }
      } catch (error) {
        alert("An unexpected error occurred. Please try again.");
        console.error("Error uploading image:", error);
      } finally {
        setMyForm({ overlay: false });
      }
    // }
  };
  

  const sendFileToPodAPI = async (biltyId, file) => {
    const podApiUrl = `${SERVER_URL}/pod_image?image_id=${biltyId}&type=${"bilty_pod"}&client_name=${"rcc"}`;
    const formData = new FormData();
    formData.append("file", file);
  
    try {
      const response = await fetch(podApiUrl, {
        method: "POST",
        body: formData,
      });
  
      if (response.ok) {
        alert("Image uploaded successfully");
        window.location.reload();
      } else {
        alert("Failed to upload image. Please try again.");
      }
    } catch (error) {
      alert("An unexpected error occurred. Please try again.");
      console.error("Error uploading image:", error);
    }
  };


  const sendPhotoToAPI = async () => {
    if ( photo) {
      setMyForm({ overlay: true });
  
      const byteString = atob(photo.split(',')[1]);
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const uint8Array = new Uint8Array(arrayBuffer);
      for (let i = 0; i < byteString.length; i++) {
        uint8Array[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([uint8Array], { type: 'image/png' });
  
      const file = new File([blob], "pod_image.png", { type: 'image/png' });
  
      try {
        if (!isBiltyNoEntered) {
          const apiUrl = `${SERVER_URL}/pod_image/check-qr?type=bilty_pod&client_name=rcc`;
          const formData = new FormData();
          formData.append("file", file);
  
          const response = await fetch(apiUrl, {
            method: "POST",
            body: formData,
          });
  
          const data = await response.json();
  
          if (!response.ok || data.status_code === 406) {
            const biltyNo = window.prompt(`Error: ${data.detail}\nPlease enter your Bilty No:`);
            if (biltyNo) {
              await fetchBiltyData(biltyNo);
            } else {
              alert("Bilty No is required. Please try again.");
            }
          } else {
            alert("Image uploaded successfully");
            window.location.reload();
          }
        } else if (biltyId) {
          const podApiUrl = `${SERVER_URL}/pod_image?image_id=${biltyId}&type=bilty_pod&client_name=rcc`;
          const formData = new FormData();
          formData.append("file", file);
  
          const response = await fetch(podApiUrl, {
            method: "POST",
            body: formData,
          });
  
          if (response.ok) {
            alert("Image uploaded successfully");
            window.location.reload();
          } else {
            alert("Failed to upload image. Please try again.");
          }
        } else {
          alert("Bilty ID is not available. Unable to proceed with the upload.");
        }
      } catch (error) {
        alert("An unexpected error occurred. Please try again.");
        console.error("Error uploading image:", error);
      } finally {
        setMyForm({ overlay: false });
      }
    }
  };
  

  const capturePhoto = () => {
    if (videoRef.current) {
      const canvas = document.createElement("canvas");
      canvas.width = videoRef.current.videoWidth;
      canvas.height = videoRef.current.videoHeight;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
  
      const capturedPhoto = canvas.toDataURL("image/png");
      setPhoto(capturedPhoto);
  
      stopCamera();
    } else {
      console.error("Video reference is not initialized.");
    }
  };

  const regeneratePhoto = () => {
    setPhoto(null);
    setCameraActive(true);
    startCamera();
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    setBiltyIdFromUrl(urlParams.get("bilty_id"));
  }, []);

  return (
    <div className="page-addPhoto">
      <LoadingOverlay
        active={myForm.overlay}
        spinner
        text="Loading your content..."
        styles={{
          wrapper: {
            // width: '400px',
            // height: '400px',
            overflow: true ? "hidden" : "scroll",
          },
        }}
      />
      <span className="pod-title" >Upload POD</span>
      <div className="capture-area">
        <div className="capture-btn">
          {!photo && <button  
            onClick={handleImageUpload}
            className="capture-btn-names"
          >
            Choose File
          </button>}
          {!photo && <button 
            onClick={capturePhoto}
            disabled={captureDisabled}
            className="capture-btn-names"
          >
            Capture
          </button>}

          {isMobileDevice() && !photo && (
            <button 
              onClick={toggleCamera}
              className="capture-btn-names"
            >
              {facingMode === "environment" ? "Front Camera" : "Back Camera"}
            </button>
          )}

          {photo && <button 
            onClick={regeneratePhoto}
            className="capture-btn-names"
          >
              Regenerate
          </button>}

          {photo && <button 
            // disabled={!biltyIdFromUrl} 
            onClick={sendPhotoToAPI}
            className="capture-btn-names"
          >
            Submit
          </button>}
        </div>
        {!photo && <video className="camera-size" ref={videoRef} autoPlay />}
        {photo && <img src={photo} alt="Captured" className="captured-size" />}
      </div>
    </div>
  );
};

export default AddPhoto;