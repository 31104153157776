const SERVER_IP = "localhost";
const SERVER_PORT = "8000";
const TRANSPORTER_NAME = "RAJ CARRYING CARGO PVT LTD";


const USE_OVERLAY = true;

const getLocalFlag = () => {
    try{
        let lo = localStorage.getItem("local_flag") === 'true';
        console.log("Inansdsnns", lo);
      return lo
    } catch(e){
      return false
    }
  }
var SERVER_URL = ""
if (getLocalFlag()){
    console.log("in if")
    SERVER_URL = "https://backend.myrcc.in:8001";
}
else{
    console.log("in else")
    SERVER_URL = "https://backend.myrcc.in";
}
var SERVER_URL_2 = "https://backend.myrcc.in:8001";
// SERVER_URL = "http://" + SERVER_IP + ":" + SERVER_PORT;  
var UTILITY_SERVER_URL =  "https://utility.myrcc.in:8020";
var PRINT_SERVER_URL = "https://utility.myrcc.in:8020";

export { SERVER_URL, USE_OVERLAY, TRANSPORTER_NAME, UTILITY_SERVER_URL, SERVER_URL_2, PRINT_SERVER_URL}
