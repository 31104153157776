import React, { useState } from "react";
import "./errorModal.css";
import useSessionVariables from "./useSessionVariables";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { SERVER_URL } from "../config/config";

function ErrorModal({ closeModal }) {
  const sessionObject = useSessionVariables();
  const [password, setPassword] = useState("");
  const [response, setResponse] = useState({});
  const history = useHistory();

  const msgObj = {
    done: "Password changed Successfully!",
    fail: "Something went wrong! Could not update the password",
  };

  const handleSubmit = async () => {
    console.log("handle submit");

    const url =
      SERVER_URL +
      "/user/change-password" +
      `?id=${sessionObject.sessionVariables.user_id}` +
      `&new_password=${password}`;

    const response = await fetch(url, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      setResponse({
        flag: 0,
        message: "",
      });
    } else {
      const data = await response.json();
      localStorage.clear();
      closeModal();
      window.location.href = "/";
      console.log({ data });
      setResponse(data);
    }
  };

  return (
    <>
      <div className="overlay"></div>
      <div className="pop-up-containerr" id="popupContainer">
        <div className="pop-up-header">
          <span>Please Change Password</span>
          {/* <span className="pop-up-close">×</span> */}
        </div>
        <div className="page-marfatiya-wise">
          <div className="signup-form-container">
            <div className="form-header"> Change Password</div>
            <div className="cp-wrapper">
              <div className="form-row">
                <label className="cp-form-label"> New Password: </label>
                <input
                  className="form-input"
                  type="text"
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyDown={(a) => {
                    if (a.key == "Enter") {
                      document.getElementById("password").focus();
                    }
                  }}
                />
              </div>
              <div>
                <button
                  onClick={handleSubmit}
                  id="password"
                  name="password"
                  type="submit"
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ErrorModal;
